/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

/* =======================
    TABLE OF CSS
    1. Fonts 
    2. General 
    3. Left Menu 
    4. Topbar 
    5. Page Head 
    6. Footer 
    7. Horizontal Nav 
    8. Right Sidebar 
    9. Layouts 
    10. Helper 
    11. Social 
    12. Widgets 
    13. Custom Checkbox 
    14. Custom Radio 
    15. Print 
    16. Preloader 
    17. Plugins 
    18. Components Demo 
    19. Tasks 
    20. Email 
    21. Timeline 
    22. Extra Pages 
    23. Authentication 
   ======================= */

//Fonts
/* 1. Fonts */
@import 'custom/fonts/fonts';

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';

// Structure
/* 2. General */
@import 'custom/structure/general';
/* 3. Left Menu */
@import 'custom/structure/left-menu';
/* 4. Topbar */
@import 'custom/structure/topbar';
/* 5. Page Head */
@import 'custom/structure/page-head';
/* 6. Footer */
@import 'custom/structure/footer';
/* 7. Horizontal Nav */
@import 'custom/structure/horizontal-nav';
/* 8. Right Sidebar */
@import 'custom/structure/right-sidebar';
/* 9. Layouts */
@import 'custom/structure/layouts';

@import 'custom/components/uploader';

//Components
/* 10. Helper */
@import 'custom/components/helper';
/* 11. Social */
@import 'custom/components/social';
/* 12. Widgets */
@import 'custom/components/widgets';
/* 13. Custom Checkbox */
@import 'custom/components/custom-checkbox';
/* 14. Custom Radio */
@import 'custom/components/custom-radio';
/* 15. Print */
@import 'custom/components/print';
/* 16. Preloader */
@import 'custom/components/preloader';

// Plugins
/* 17. Plugins */
@import 'custom/plugins/slimscroll';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/calendar';
@import 'custom/plugins/react-select';
@import 'custom/plugins/multiple-select';
@import 'custom/plugins/flatpickr';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/parsley';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/editor';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/datatable';
@import 'custom/plugins/table-list-js';
@import 'custom/plugins/kanban';
@import 'custom/plugins/metismenu';

//Pages
/* 18. Components Demo */
@import 'custom/pages/components-demo';
/* 19. Tasks */
@import 'custom/pages/tasks';
/* 20. Email */
@import 'custom/pages/email';
/* 21. Timeline */
@import 'custom/pages/timeline';
/* 22. Extra Pages */
@import 'custom/pages/extra-pages';
/* 23. Authentication */
@import 'custom/pages/authentication';

.question-index {
    &:hover {
        .remove-choice {
            display: flex;
        }
    }
}

.remove-choice {
    background-color: red;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    color: white;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 6px;
    position: absolute;
    top: -5px;
    left: 0;
}

.pro-services-list {
    padding-top: 40px;
    padding-bottom: 12px;
    padding-right: 0;
    margin-left: auto;
    display: flex;
    .service-list {
        position: relative;
        height: 420px;
        display: flex;
        flex-direction: column;
        h4 {
            border-bottom: 1px solid #e2e2e9;
            text-align: center;
            padding: 20px;
            margin: 0;
        }
        width: 240px;
        background-color: #fcfcfc;
        border: 1px solid #dddddd;
        button {
            width: 100%;
            padding: 12px 16px;
            display: block;
            text-align: left;
            border: 0;
            background-color: transparent;
            &.active-service {
                background-color: #ededed;
                font-weight: bold;
            }
        }
        .servic {
            flex: 1;
            overflow-y: auto;
        }
        .add-service {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            svg {
                position: absolute;
                right: 15px;
                fill: #dddddd;
            }
            input {
                width: 100%;
                padding: 12px 16px;
                border: 1px solid #dddddd;
                outline: none;
            }
        }
    }
    .service-details {
        border: 1px solid #dddddd;
        flex: 1;
        height: 420px;
        overflow-y: auto;
        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            ul {
                display: flex;
                list-style: none;
                padding: 0;
                margin: 0;
                width: 85%;
                li {
                    width: 33.33%;
                    button {
                        padding: 16px 0;
                        width: 100%;
                        background-color: #fcfcfc;
                        border: 1px solid #dddddd;
                        &.active-tab {
                            background-color: #ededed;
                            font-weight: bold;
                        }
                        &:hover {
                            background-color: #f4f4f4;
                        }
                    }
                }
            }
            .remove-service {
                background-color: transparent;
                border: 0;
                margin-right: 16px;
            }
        }
        main {
            padding: 20px;
            .question {
                margin-bottom: 20px;
                h5 {
                    margin-bottom: 8px;
                }
                .select-all {
                    border: 0;
                    background-color: transparent;
                    color: #2e7cf6;
                    padding: 0;
                    margin-bottom: 6px;
                }
            }
            .price-info {
                padding: 10px 0;
                .sub-radios {
                    margin-left: 20px;
                }
            }
        }
    }
}

.pro-choices-inputs {
    position: relative;
    &:hover {
        button {
            display: flex;
        }
    }
    button {
        display: none;
        position: absolute;
        top: -2px;
        right: -2px;
        width: 14px;
        height: 14px;
        background-color: red;
        color: white;
        border: 0;
        border-radius: 20px;
        align-items: center;
        justify-content: center;
        padding: 6px;
    }
}

.profile_image_preview {
    position: relative;
    width: 200px;
    height: 200px;
    button {
        position: absolute;
        right: 5px;
        top: 5px;
        border: 0;
        background-color: #bb2727;
        border-radius: 999px;
        padding: 3px;
        svg {
            fill: white;
        }
    }
}

.work_images_preview {
    position: relative;
    height: 90px;
    button {
        position: absolute;
        right: 5px;
        top: 5px;
        border: 0;
        background-color: #bb2727;
        border-radius: 999px;
        padding: 1px;
        svg {
            fill: white;
        }
    }
}

.search-results {
    border: 1px solid #ddd;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 240px;
    margin-top: -15px;
    li {
        button {
            width: 100%;
            background-color: transparent;
            border: 1px solid #ddd;
            padding: 10px 0;
        }
    }
}

.drop-up .Select-menu-outer {
    top: auto;
    bottom: 100%;
}

.add-first-service {
    background-color: #ececec;
    padding: 20px;
    position: relative;
    input {
        width: 240px;
        padding: 4px;
    }
    .results {
        margin-top: 8px;
        button {
            margin-bottom: 8px;
            border: 1px solid #bdbdbd;
            color: black;
            width: 120px;
            display: block;
            padding: 8px 4px;
        }
    }
}
.rdw-editor-wrapper {
    box-sizing: content-box;
    border: 1px solid #e2e7f1;
    width: 100%;
}
.rdw-colorpicker-wrapper {
    display: none !important;
}
.rdw-image-wrapper {
    display: none !important;
}
.rdw-emoji-wrapper {
    display: none !important;
}
.rdw-editor-main {
    height: auto !important;
    overflow: auto;
    
    box-sizing: border-box;
    border: 1px solid #e2e7f1;
}
.DraftEditor-root {
    position: relative;
    top: -13px;
}