.uploader-left {
    width: 140px;
}

.uploader-outer input {
    display: none;
}
.uploader {
    display: flex;
    align-items: center;
    justify-self: center;
    height: 140px;
    border: 1px dashed #d9d9d9;
    margin-bottom: 10px;
    cursor: pointer;
    h4 {
        font-size: 14px;
    }
    .inside {
        text-align: center;
        width: 100%;
    }
}
