.pagination input{
    display: none !important;
}


.pagination input:first-child{
    display: block !important;
}

.pagination{
    float: right;
    margin-top: 1rem;
}

@media (min-width:768px){
    #jump{
        margin-top: 1rem;
    }
    .pagination{
        margin-top: -3rem;
    }
}
